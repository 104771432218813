import $ from 'jquery'

var $questions = $('.question')
var $toggles = $('.toggle')
var $containers = $('.opportunity-container')
var $open = $('.opportunity-open')
var $close = $('.opportunity-close')

$toggles.click(function (e) {
	var category = e.currentTarget.dataset.toggle

	$questions.each(function (i, question) {
		var $question = $(question)

		if ($question.hasClass(category)) $question.addClass('active')
		else $question.removeClass('active')
	})
})

$open.click(function (e) {
	$containers.removeClass('active')
	$(this).parent().addClass('active')
})

$close.click(function (e) {
	$containers.removeClass('active')
})


/// Fix the journey nav on scroll
$(window).scroll(function () {
var journeyNav = $('.map-container .menu'),
		navHeight = $('.map-container .menu').height(),
		journey = $('.map-container'),
 		scrollTop = $(window).scrollTop(),
		elementOffset = journey.offset().top,
		distance      = (elementOffset - scrollTop);


// var offset = $(this).height();
if ( ($(window).scrollTop()) > (elementOffset - navHeight*5) && ($(window).scrollTop()) < (elementOffset + journey.height()*.58) ) {
    journeyNav.addClass('fixed');
		journeyNav.removeClass('bottom-lock');
}
else if (($(window).scrollTop()) > (elementOffset + journey.height()*.58)) {
		journeyNav.removeClass('fixed');
    journeyNav.addClass('bottom-lock');
}
else {
    journeyNav.removeClass('fixed');
		journeyNav.removeClass('bottom-lock');
}
});
