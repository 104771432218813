import './load.js'

import '../styles/index.scss'

import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic'
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import TweenMax from 'gsap/src/uncompressed/TweenMax'
import TimelineMax from 'gsap/src/uncompressed/TimelineMax'

import './accordion.js'
import './nav.js'
// import './fix-logo.js';
import './audio-clip.js'
import './analytics.js'
// import './header-parralax.js'
import './mbg-scrollmagic.js'
// import './body-scroll-lock.js';
import './modal.js'
import './ujw.js'

window.SmoothScroll = require('smooth-scroll')

// init controller
const controller = new ScrollMagic.Controller()
